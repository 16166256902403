<template>
  <v-container id="detalle" fluid tag="section">
    <v-card v-if="transaccion && transaccionDetalle.length">
      <v-toolbar flat color="white">
        <v-toolbar-title>Detalle del Pedido</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="transaccion.ente"
              label="Ente"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="transaccion.entidad"
              label="Delegación"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="transaccion.cliente"
              label="Cliente"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="transaccion.fecha"
              label="Fecha Inicio"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="transaccion.estado"
              label="Estado"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="transaccion.metodo_pago"
              label="Método Pago"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="transaccion.total"
              label="Total"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="transaccionDetalle"
          class="elevation-1"
        >
          <template v-slot:[`item.total`]="{ item }">
            {{ item.cantidad * item.precio_unitario }}
          </template>
        </v-data-table>
        <v-btn color="primary" @click="$router.push('/portal/transacciones')"
          >Atrás</v-btn
        >
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      headers: [
        { text: "Artículo", value: "articulo" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio unitario", value: "precio_unitario" },
        { text: "Total", value: "total" },
      ],
    };
  },
  computed: {
    ...mapState("transacciones", ["transaccionDetalle", "transacciones"]),
    transaccion() {
      const transaccionId = this.$route.params.transaccion_id;
      const transaccion =
        this.transacciones.find((trans) => trans.id == transaccionId) || {};
      return {
        ...transaccion,
        cliente: this.isNumeric(transaccion.cliente_id)
          ? "Cliente Test"
          : transaccion.cliente || "-",
        entidad: this.isNumeric(transaccion.entidad_id)
          ? "Entidad Test"
          : transaccion.entidad || "-",
        ente: transaccion.ente || "-",
      };
    },
  },
  created() {
    const transaccionId = this.$route.params.transaccion_id;
    this.fetchTransaccionDetalle(transaccionId);
  },
  methods: {
    ...mapActions("transacciones", ["fetchTransaccionDetalle"]),
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
  },
};
</script>

<style scoped>
.data__table {
  margin-top: 20px;
}
</style>
